.thumbnail {
    width: 400px;
    height: 290px;
    margin: 16px 8px 0 8px;
    cursor: pointer;
    overflow: hidden;
    background: lightgrey;
}

.thumbnailMobile {
    width: 360px;
    height: 240px;
}

.overlay,
.image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.4s;
}

.overlay {
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: rgba(0,0,0,0.5);
    color: white;
    opacity: 0;
}

.thumbnail:hover .image,
.thumbnail:active .image {
    transform: scale(1.2);
}

.thumbnail:hover .overlay,
.thumbnail:active .overlay {
    opacity: 1;
}

.titleText {
    padding: 40px;
}
