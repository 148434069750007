.GamePage {
    margin: 0;
    padding: 61px 0;
    box-sizing: border-box;
    width: 100%;
    min-height: calc(100vh - 200px - 130px);
}

.GamePage a {
    color: #5ec9f1;
    text-decoration: none;
    font-weight: 700;
}
