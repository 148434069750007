.mainContent {
  max-width: 770px;
  margin: 0 auto;
}

.mobileContent {
  padding: 0 20px;
}

.mainContent p {
  margin: 30px 0
}