.container {
  min-height: 150px;
  background-color: white;
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.mobileContainer {
  min-height: 100px;
}

.image {
  width: 100%;
}

.description {
  margin-top: 10px !important;
  font-size: 0.9em !important;
  margin-bottom: 0px !important;
}