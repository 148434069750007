.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 0;
}

.title h1 {
  font-size: 2.5em;
}

.text {
  font-size: 1.4em;
}

.text a {
  text-decoration: none;
  color: #5ec9f1;
}

.subText {
  margin-top: 20px;
  text-align: center;
}

.divider {
  width: 400px;
  height: 2px;
  margin: 20px 0;
  background-color: black;
}

.dividerMobile {
  width: 300px;
}
