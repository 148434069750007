.navbar {
    height: 50px;
    width: 100vw;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column;
    z-index: 100;
    transition: .5s linear;
}

.title {
    margin-top: 0;
    margin-bottom: 10px;
    width: 547px;
    height: 52px;
}

.navitems a {
    text-decoration: none;
    margin: 10px;
    transition: 0.2s;
    cursor: pointer;
    color: #5ec9f1;
}

.active,
.navitems a:active {
    color: black !important;
}
