.instaContainer  {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 250px;
}

.scrollContainer {
  display: flex;
  height: 100%;
  max-width: 1264px;
  overflow-x: scroll;
}

.thumbContainer {
  width: 250px;
  height: 250px;
  margin-right: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.instaThumbnail {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;;
  transition: all .3s linear;
  cursor: pointer;
}

.thumbContainer:hover .instaThumbnail {
  transform: scale(1.2);
}

.buttonLeft, .buttonRight {
  margin-left: 22px;
  margin-right: 22px;
  z-index: 5;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.buttonLeft {
  background-image: url('./assets/left.png');
}
.buttonRight {
  background-image: url('./assets/right.png');
}

.seeMore {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scrollContainer a {
  text-decoration: none;
  color: #5ec9f1;
}