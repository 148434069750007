.LinkGroup {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
}

.LinkGroup a {
    margin: 0 20px;
}

.LinkGroup div:last-of-type span {
    display: none;
}