.selectionContainer {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 150px;
}

.allthumbnails {
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
};