.filterBar {
    display: flex;
    justify-content: space-between;
    height: 45px;
    width: 625px;
    margin-bottom: 30px;
    color: #5ec9f1;
    font-size: 1.4em;
}

.filterBar div {
    width: 200px;
    border: 2px solid #5ec9f1;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
}

.filterBar div:hover,
.filterBar div:active,
.active {
    color: white;
    background-color: #5ec9f1;
    font-weight: 700;
}
