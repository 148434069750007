.header {
    height: 100vh;
    position: relative;
}

.mobileHeader {
    background-image: url('https://drive.google.com/thumbnail?id=1XRPHab44WSvpWT-8M2ZHFxMkrM59EUen&sz=w1200');
    background-size: cover;
    background-color: #B2EDFF;
}

.titleContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.divider {
    width: 400px;
    height: 3px;
    margin: 50px 0 20px;
    background-color: white;
}

.dividerMobile {
    width: 300px;
}

.title {
    font-family: 'Staatliches', sans-serif;
    font-size: 5.5em;
    text-align: center;
    color: white;
    text-shadow: rgba(101,190,234,0.4) 0px 3px 4px;
}

.titleMobile {
    font-size: 2.2em;
    text-align: center;
}

.subTitle {
    font-size: 3em;
    font-family: 'Staatliches', sans-serif;
}

.subTitleMobile {
    font-size: 1.5m;
    text-align: center;
}

.scrollButtonContainer {
    width: 100%;
    position: absolute;
    bottom: 30px;
    display: flex;
    justify-content: center;
}

.scrollButton {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    animation: bounce 4s infinite;
}


@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}