.iconBoxes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 110px;
}

.iconBox {
  display: flex;
  flex-direction: column;
  width: 260px;
  align-items: center;
  text-align: center;
  margin: 0 20px 20px;
}

.iconBox h2{
  font-size: 1.5em;
}
