.container {
  display: flex;
  justify-content: center;
}

.mobileContainer {
  flex-flow: column;
  width: 100%;
}

.thumbnail {
  margin: 10px;
  background: lightgray;
  background-position: center;
  cursor: pointer;
}

.whiteBG {
  background: white;
  background-repeat: no-repeat;
  background-position: center;
}

.description {
  text-align: center;
  font-size: 0.9em;
}
