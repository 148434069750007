.Footer {
    width: 100%;
    height: 200px;
    background: #5ec9f1;
    box-sizing: border-box;
    padding: 50px;
    text-align: center;
    color: white;
}
.Footer p {
    margin: 5px;
}
.Footer a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.Footer a:hover,
.Footer a:active,
.Footer a:focus {
    color: grey;
}
