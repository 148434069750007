.modalBackground {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 200;
    background-color: rgba(0,0,0,0.7);
}

.image {
    padding: 50px auto;
    height: auto;
    max-width: 80vw;
    max-height: 80vh;
    object-fit: contain;
}

.textArea {
    border-bottom: 60px solid white;
}

.mobileImage {
    padding: 20px auto;
    border-bottom: 0;
    max-width: 90vw;
    max-height: 90vh;
}

.closeButton {
    opacity: 0.4;
    transform: translate(calc(100% - 20px), 25px);
    cursor: pointer;
}

.closeButton:hover {
    opacity: 1;
}

.subtext {
    display: flex;
    justify-content: space-between;
    background:white;
    margin: 0;
    transform: translateY(-44px);
    max-width: 80vw;
    height: 30px;
    text-align: center;
}

.subtextSmall {
    transform: translateY(-52px) !important;
    max-width: 268px !important;
}

.subtextMedium {
    transform: translateY(-52px) !important;
    max-width: 440px !important;
}

.leftButton, .rightButton {
    position: absolute;
    background: grey;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    opacity: 0.2;
    margin: 10px;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    cursor: pointer;
    transition: opacity 0.1s linear;
}

.leftButton:hover, .rightButton:hover {
    opacity: 1;
}

.leftButton {
    left: 0;
}
.rightButton {
    right: 0;
}

@media (max-width:600px) {
    .leftButton, .rightButton {
        width: 50px;
        height: 50px;
        font-size: 30px;
    }
}